<template>
    <div class="news">
        <h3 class="title">{{obj.title}}</h3>
        <div v-html="obj.content"></div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                obj: ''
            }
        },
        methods: {
            getNewsList(id) {
                let param = {_id:id}
                this.$api.newsList(param).then(res=>{
                    console.log('res', res)
                    this.obj = res.data[0]
                })
            }
        },
        mounted () {
            const id = this.$route.params && this.$route.params.id
            this.getNewsList(id);
        },
    }
</script>

<style lang="less" scoped>
    .news{
        width: 1200px;
        margin: 0 auto;
        line-height: 30px;
        font-size: 14px;
        .title{
            padding: 20px;
        }
    }
</style>